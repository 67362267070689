import { toast } from "react-toastify";

function showToast(msg: string, type: string, time: number| false = 3000,hideProgressBar: boolean = true) {
  if (type === "error") {
    toast.error(msg, {
    position: "top-right",
    autoClose: time, 
    hideProgressBar: hideProgressBar,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
    
  } else if (type === "success") {
    toast.success(msg, {
      position: "top-right",
      autoClose: time, 
      hideProgressBar: hideProgressBar,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  else if (type === "warning") {
    toast.warning(msg, {
      position: "top-right",
      autoClose: time, 
      hideProgressBar: hideProgressBar,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
   
  }

  export default showToast