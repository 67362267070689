import { Receipt } from "../data/Receipt";

interface IProps {
  receipt: Receipt
  isOpen: boolean;
  onClose: () => void;
}

function formatDateWithIntl(datetimeString: string): {date: string, time: string} {
  const dateTime:Date = new Date(datetimeString);
  // Use Intl.DateTimeFormat with options for day, month, and year
  const formatter = new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
  });

  const TimeFormatter = new Intl.DateTimeFormat('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Use 24-hour format
});

  return {date: formatter.format(dateTime), time: TimeFormatter.format(dateTime)} ;
}

function formatNumberInArabic(number: number,useGrouping:boolean = true): string {
  const formatter = new Intl.NumberFormat('ar', {
      useGrouping: useGrouping // Optional, if you want to disable grouping (e.g., thousands separator)
  });

  return formatter.format(number);
}
const ReceiptDialog = (
  props: IProps
) => {
  if (!props.isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex  items-top justify-center" >
      
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full  leading-5 text-[.82rem] font-bold">
        <h1 className="text-center">
          {props.receipt.merchant_ar}
        </h1>
        <h1 className="text-center">
          {props.receipt.merchant_en}
        </h1>
        <h1 className="text-center">{props.receipt.add_ar}</h1>
        <h1 className="text-center">{props.receipt.add_en}</h1>

        <div className="text-[.6rem] font-normal leading-5">
          <div className="flex justify-between">
            <h1>{formatDateWithIntl(props.receipt.date).date}</h1>
            <h1>{formatDateWithIntl(props.receipt.date).time}</h1>
          </div>

          <div className="flex justify-between">
            <h1>{props.receipt.acquirerCode}</h1>
            <h1>{props.receipt.merchantId}</h1>
            <h1>{props.receipt.terminalId}</h1>
          </div>
          <div className="flex justify-between">
            <h1>{props.receipt.merchantCategoryCode}</h1>
            <h1>{props.receipt.stan}</h1>
            <h1>{props.receipt.madaAppVersion}</h1>
            <h1>{props.receipt.rrn}</h1>
          </div>
        </div>

        <div className="flex justify-between mt-2">
        <h1>{props.receipt.cardSchemeEn}</h1>
          <h1 className="text-end">{props.receipt.cardSchemeAr}</h1>
        </div>
        <div className="flex justify-between mt-2" >
        <h1>{props.receipt.transactionTypeEn}</h1>
          <h1 className="text-end">{props.receipt.transactionTypeAr}</h1>        
        </div>

        <div className="flex justify-between mt-2">
          <h1>{props.receipt.pan}</h1>
          <h1>{props.receipt.cardExpiration}</h1>
        </div>

        <div className="flex justify-between mt-2">
          <h1>{formatNumberInArabic(Number(props.receipt.amountAuthorizedValue))}</h1>
          <h1>{props.receipt.amountAuthorizedAr}</h1>
        </div>

        <div className="flex justify-between mt-2">
          <h1>{props.receipt.amountAuthorizedEn}</h1>
          <h1>{props.receipt.amountAuthorizedValue}</h1>
        </div>

        <div className="flex flex-col  mt-2">
          <h1 className="text-center">{props.receipt.statusMessageAr}</h1>
          <h1 className="text-center">{props.receipt.statusMessageEn}</h1>
          <h1 className="text-center">{props.receipt.verificationMethodAr}</h1>
          <h1 className="text-center">{props.receipt.verificationMethodEn}</h1>
        </div>

        <div className="flex justify-between mt-2">
          <h1>{formatNumberInArabic(Number(props.receipt.approvalCodeValue),false)}</h1>
          <h1>{props.receipt.approvalCodeAr}</h1>
        </div>

        <div className="flex justify-between mt-2">
          <h1>{props.receipt.approvalCodeEn}</h1>
          <h1>{props.receipt.approvalCodeValue}</h1>
        </div>

        <div className="flex justify-between mt-2">
          <h1>{props.receipt.endDate}</h1>
          <h1>{props.receipt.endTime}</h1>
        </div>

        <div className="flex flex-col  mt-4">
          <h1 className="text-center">{props.receipt.thanksMessageAr}</h1>
          <h1 className="text-center">{props.receipt.thanksMessageEn}</h1>
          <h1 className="text-center">{props.receipt.saveReceiptMessageAr}</h1>
          <h1 className="text-center">{props.receipt.saveReceiptMessageEn}</h1>
        </div>

        <div className="flex flex-col  mt-4">
          <h1 className="text-center">** نسخة العميل **</h1>
          <h1 className="text-center">** Customer Copy **</h1>
        </div>

        <div className="text-[.6rem] flex font-normal mt-3 justify-center gap-2">
          <h1>{props.receipt.entryMode}</h1>
          <h1>{props.receipt.actionCode} </h1>
          <h1> {props.receipt.applicationIdentifier}</h1>
          <h1>{props.receipt.terminalVerificationResult}</h1>
        </div>

        <div className="text-[.6rem] flex font-normal  justify-center gap-2">
          <h1>{props.receipt.transactionStateInformation}</h1>
          <h1>{props.receipt.cardholaderVerficationResult} </h1>
          <h1> {props.receipt.cryptogramInformationData}</h1>
          <h1>{props.receipt.applicationCryptogram}</h1>
          <h1>{props.receipt.kernelId}</h1>
        </div>
        <div className="text-[.6rem] flex font-normal  justify-center gap-2">
          <h1>{props.receipt.paymentAccountReference}</h1>
        </div>

        <h1 className="text-center mt-4">Powered By Pass Platform</h1>

        {/* <div className="flex justify-center space-x-4">
          <button
            onClick={props.onClose}
            className="bg-gray-300 text-black px-4 py-2 rounded-md"
          >
            Cancel
          </button>
        </div> */}
      
      </div>
      <svg onClick={props.onClose} viewBox="0 0 24 24" className="w-12 h-12 hover:cursor-pointer">
              <circle cx="12" cy="12" r="10" fill="#c7c7c7" />
              
              <path
                fill="white"
                d="M15 9l-6 6M9 9l6 6"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
    </div>
  );
};

export default ReceiptDialog;
