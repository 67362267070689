// src/App.tsx
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import Login from "./pages/Login";
import Layout from "./components/Layout";
import Cashier from "./pages/Cashier";
import ProtectedRoute from "./components/ProtectedRoute";
import Transactions from "./pages/Transactions";
import Terminals from "./pages/Terminals";
import { WebSocketProvider } from "./WebSocketContext";
import { ToastContainer } from "react-toastify";
import MerchantOnboarding from "./pages/NewMerchant";

const App = () => {

  return (
    <AuthProvider>
      <WebSocketProvider>
        <Router>
          <Routes>
            {/* <Route path="/cuppertino/remalcomplex/login" element={isAutghenticated ? <Navigate to="/" /> : <Login />} /> */}
            <Route path="/cuppertino/remalcomplex/login" element={<Login />} />

            <Route
              path="/home"
              Component={() => {
                window.location.href = "/home.html";
                return null;
              }}
            />

            <Route
              path="/cuppertino/remalcomplex/new"
              element={
                <MerchantOnboarding />
              }
            />

            <Route
              path="/"
              element={
                <ProtectedRoute
                  element={
                    <Layout>
                      <Cashier />
                    </Layout>
                  }
                />
              }
            />
            <Route
              path="/terminals"
              element={
                <ProtectedRoute
                  element={
                    <Layout>
                      <Terminals />
                    </Layout>
                  }
                />
              }
            />
            <Route
              path="/transactions"
              element={
                <ProtectedRoute
                  element={
                    <Layout>
                      <Transactions />
                    </Layout>
                  }
                />
              }
            />
          </Routes>
        </Router>
      </WebSocketProvider>
      <ToastContainer />
    </AuthProvider>
  );
};

export default App;
