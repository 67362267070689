
import React, { createContext, useState, ReactNode } from 'react';

// Define the structure for the context data
export interface AuthContextType {
  token: string | null;
  role: string | null;
  login: (token: string) => void;
  logout: () => void;
  // getToken: () => string | null;
  // getRole: () => string | null;
  setRole: (role: string) => void;
}

// Create the context
export const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Define the provider component
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('accessToken'));
  const [role, setRoleState] = useState(localStorage.getItem('role'));
  
  // Function to retrieve token from localStorage (or sessionStorage)
  // const getToken = (): string | null => {
  //   return localStorage.getItem('accessToken');
  // };

  // const getRole = (): string | null => {
  //   return localStorage.getItem('role');
  // };

  const setRole = (role:string) =>{
    setRoleState(role)
    localStorage.setItem('role', role);
  }

  
  const login = (token: string) => {
    localStorage.setItem('accessToken', token);
    setToken(token);
  };

  // Logout function: remove token and set isAuthenticated to false
  const logout = () => {
    localStorage.removeItem('accessToken');
    setToken(null);
    localStorage.removeItem('role');
    setRoleState(null);
  };

  // Provide state and actions to the context consumers
  return (
    <AuthContext.Provider value={{  token,role, login, logout,setRole }}>
      {children}
    </AuthContext.Provider>
  );
};


