import React from 'react';

interface TransactionDialogProps {
  transactionId: string;
  status: 'approved' | 'declined' | 'canceled';
  onClose: () => void;
}

const TransactionDialog: React.FC<TransactionDialogProps> = ({ transactionId, status, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 shadow-lg text-center">
        <div className={`flex justify-center items-center mb-4 w-96`}>
          {status === 'approved' ? (
            <svg viewBox="0 0 24 24" className="w-12 h-12">
              <circle cx="12" cy="12" r="10" fill="green" />
              <path
                fill="white"
                d="M16 8l-5.5 5.5-2.5-2.5-1.5 1.5 4 4 7-7z"
              />
            </svg>
          ) : (
            <svg viewBox="0 0 24 24" className="w-12 h-12">
              <circle cx="12" cy="12" r="10" fill="red" />
              
              <path
                fill="white"
                d="M15 9l-6 6M9 9l6 6"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          )}
        </div>
        <div className="mb-4">
          {status === 'approved' ? (
            <p className="text-lg font-semibold text-green-600">Transaction #{transactionId} is approved</p>
          ) : (
            <p className="text-lg font-semibold text-red-600">Transaction #{transactionId} is {status}</p>
          )}
        </div>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default TransactionDialog;
