import React from 'react';

interface CheckoutDialogProps {
  isOpen: boolean;
  totalAmount: number;
  onClose: () => void;
  onPay: () => void;
}

const CheckoutDialog: React.FC<CheckoutDialogProps> = ({ isOpen, totalAmount, onClose, onPay }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-xl font-semibold mb-4">Checkout</h2>
        <p className="mb-4">Total Amount: <span className="font-bold">{totalAmount.toFixed(2)} SAR</span></p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="bg-gray-300 text-black px-4 py-2 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={onPay}
            className="bg-primary text-white px-4 py-2 rounded-md"
          >
            Pay
          </button>
        </div>
      </div>
    </div>
  );
};

export default CheckoutDialog;
