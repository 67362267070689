import { useEffect, useState } from "react";
import { apiUrl } from "../api";
import ReceiptDialog from "../components/ReceiptDialog";
import { Receipt } from "../data/Receipt";

interface Transaction {
  id: number;
  amount: number;
  status: string;
  terminal: string;
  date: string;
  acquirerCode:string;
  terminalId:string;
  rrn:string;
  cardType:string;

}

const Transactions = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [receipt, setReceipt] = useState<Receipt>({} as Receipt);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15);
  const [totalPages, setTotalPages] = useState(1);
  const [isReceiptOpen, setIsReceiptOpen] = useState(false);

 
  function getDetails(showDetailsId: number) {
    fetch(`${apiUrl}/transactions/${showDetailsId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Include the auth token in the Authorization header
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setReceipt(data);
        setIsReceiptOpen(true);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }


  useEffect(() => {
    fetch(`${apiUrl}/transactions?page=${currentPage - 1}&size=${itemsPerPage}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}` // Include the auth token in the Authorization header
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTransactions(data.content);
        setTotalPages(data.totalPages); // 
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, [currentPage, itemsPerPage]);

  // Format date using Intl.DateTimeFormat
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(date);
  };
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  function closeDialog(): void {
   setIsReceiptOpen(false);
  }

  return (
    <div className="container mx-auto">
      <h1 className="text-2xl font-bold mb-4">Transactions</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {/* <th className="py-2 px-4 border-b">ID</th> */}
              <th className="py-2 px-4 border-b">Amount</th>
              <th className="py-2 px-4 border-b">Status</th>
              <th className="py-2 px-4 border-b">Terminal</th>            
              <th className="py-2 px-4 border-b">Date</th>
              <th className="py-2 px-4 border-b">Terminal ID</th>
              <th className="py-2 px-4 border-b">Acquirer Code</th>
              <th className="py-2 px-4 border-b">Card Type</th>
              <th className="py-2 px-4 border-b">RRN</th>
              <th className="py-2 px-4 border-b">action</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr key={transaction.id}>
                {/* <td className="py-2 px-4 border-b text-center">
                  {transaction.id}
                </td> */}
                <td className="py-2 px-4 border-b text-center">
                  {(transaction.amount/100).toFixed(2)} SAR
                </td>
                <td
                  className={`py-2 px-4 border-b text-center ${
                    transaction.status === "COMPLETED"
                      ? "text-green-500"
                      : transaction.status === "PENDING"
                      ? "text-yellow-500"
                      : transaction.status === "INITIALIZED"
                      ? "text-gray-500"
                      : "text-red-500"
                  }`}
                >
                  {transaction.status}
                </td>
                <td className="py-2 px-4 border-b text-center">{transaction.terminal}</td>
                <td className="py-2 px-4 border-b text-center">{formatDate(transaction.date)}</td>
                <td className="py-2 px-4 border-b text-center">{transaction.terminalId}</td>
                <td className="py-2 px-4 border-b text-center">{transaction.acquirerCode}</td>
                <td className="py-2 px-4 border-b text-center">{transaction.cardType}</td>
                <td className="py-2 px-4 border-b text-center">{transaction.rrn}</td>
                <td className="py-2 px-4 border-b text-center">
                  <button onClick={() => getDetails(transaction.id)} disabled={transaction.status !== "COMPLETED"} className={transaction.status === 'COMPLETED'? 'text-blue-500': 'text-gray-500'}>Show Receipt</button></td>
              </tr>
            ))}
          </tbody>
        </table>
         {/* Pagination Controls */}
       {totalPages > 1 && <div className="flex justify-center mt-4">
          <nav>
            <ul className="flex list-none">
              {Array.from({ length: totalPages }, (_, index) => (
                <li key={index} className="mx-1">
                  <button
                    onClick={() => paginate(index + 1)}
                    className={`px-3 py-1 border ${
                      currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'
                    } hover:bg-blue-500 hover:text-white`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>}
      </div>
      <ReceiptDialog
        isOpen={isReceiptOpen}
        onClose={closeDialog}
        receipt={receipt}
          />
    </div>
  );
};

export default Transactions;


