import {
  createContext,
  ReactNode,
  useCallback,
  useState,
} from "react";
import { wsUrl } from "./api";

export interface WebSocketContextType {
  connect: () => void;
  disconnect: () => void;
  sendMessage: (message: string | object) => void;
  message: string;
  isConnected: boolean;
}

// Create WebSocketContext
export const WebSocketContext = createContext<WebSocketContextType | undefined>(
  undefined
);

export const WebSocketProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [message, setMessage] = useState<string>("");
  // const reconnectTimeout = useRef<number | null>(null);
  const [isConnected, setIsconnected] = useState(false);
  const [isConnecting, setIsconnecting] = useState(false);

  // const attemptReconnect = () => {
  //   if (reconnectTimeout.current) {
  //     clearTimeout(reconnectTimeout.current);
  //   }

  //   reconnectTimeout.current = setTimeout(() => {
  //     console.log("Reconnecting WebSocket...");
  //     connect();
  //   }, 5000); // Try to reconnect after 5 seconds
  // };

  const connect = useCallback( () => {
    if (isConnected) {
      console.log("already connected to platform");
      return;
    }
    if (isConnecting) {
      console.log("already connecting to platform");
      return;
    }
    setIsconnecting(true);
    console.log("connecting...");

    const token = localStorage.getItem("accessToken");
    if (token !== null) {
      const ws = new WebSocket(wsUrl, [token]);

      ws.onopen = () => {
        console.log("connected to platform");
        setIsconnected(true);
        // if (reconnectTimeout.current) {
        //   clearTimeout(reconnectTimeout.current);
        //   reconnectTimeout.current = null;
        // }
      };

      ws.onmessage = (event) => {
        const msg = event.data as string;
        setMessage(msg);
      };

      ws.onclose = () => {
        console.log("disconnected from platform");
        setIsconnected(false);
        setIsconnecting(false);
        // attemptReconnect();
      };

      ws.onerror= (ev:Event) => {
        console.error("WebSocket error:", ev);
      }

      setSocket(ws);
    } else console.log("not authorized");
  },[]);

  const disconnect =() => {
    if (socket) {
      socket.close();
      setSocket(null);
    }
  };
 

  const sendMessage = (message: string | object) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      if (typeof message === "object") {
        socket.send(JSON.stringify(message));
      } else {
        socket.send(message);
      }
    } else {
      console.log("WebSocket is not open");
    }
  };

  return (
    <WebSocketContext.Provider
      value={{ connect, disconnect, sendMessage, message ,isConnected}}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
