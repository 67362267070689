
import { Product } from '../types';

export const products: Product[] = [
  {
    id: 1,
    name: 'product 1',
    price: 999.99,
    image: '',
  },
  {
    id: 2,
    name: 'product 2',
    price: 120.0,
    image: '',
  },
  {
    id: 3,
    name: 'product 3',
    price: 150.0,
    image: '',
  },
];
