// src/pages/Login.tsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../useAuth";
import { apiUrl } from "../api";
import showToast from "../util";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [apiLoading, setApiLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const { token ,setRole} = useAuth();

  useEffect(() => {
    if (token !=null) {
      navigate("/");
    }
  }, [navigate, token]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setApiLoading(true);
    const isTerminal=false
    const response = await fetch(`${apiUrl}/auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, password , isTerminal }),
    });

    if (response.ok) {
      const { accessToken ,role} = await response.json();
      console.log(accessToken,role);
      setRole(role);
      login(accessToken);
      navigate("/");
    } else {
      showToast("Login failed", "error");
    }

    setApiLoading(false);
  };

  return (
    <div className="h-screen flex items-center justify-center bg-gray-50">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-8 shadow-md rounded-md w-[32rem]"
      >
        <h1 className="text-xl mb-6 text-black">Login</h1>
        <div className="mb-4">
          <label htmlFor="username" className="block mb-2">
            User Name
          </label>
          <input
            type="text"
            id="username"
            className="w-full p-2 border border-gray-300 rounded-md"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block mb-2">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="w-full p-2 border border-gray-300 rounded-md"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button
          disabled={apiLoading}
          type="submit"
          className="bg-primary text-white px-4 py-2 rounded-md"
        >
          Login
        </button>

        {/* show loading */}
        {apiLoading && <p>Loading...</p>}
      </form>
    </div>
  );
};

export default Login;
